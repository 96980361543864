// App.js
import React from 'react';
import UploadImage from './UploadImage';

const App = () => {
  return (
    <div className="App">
      <UploadImage />
    </div>
  );
};

export default App;
